import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
} from "@mui/material";

import iisc from "../assests/iisc.jpg";
import photo from "../assests/IITBombay.avif";
import Delhi from "../assests/IITDelhi.jpeg";
import Madras from "../assests/IITMadras.jpg";

const allColleges = [
  {
    name: "Indian Institute of Science (IISc), Bangalore",
    description: "Photography at Plymouth: an introduction to our courses.",
    image: iisc,
  },
  {
    name: "Indian Institute of Technology (IIT), Bombay",
    description: "Suki Dhana on working with Rankin and Gillian Wearing.",
    image: photo,
  },
  {
    name: "Indian Institute of Technology (IIT), Delhi",
    description: "A short introduction to 3D model making with David Idol.",
    image: Delhi,
  },
  {
    name: "Indian Institute of Technology (IIT), Madras",
    description: "Meet the BA (Hons) Animation class of 2019.",
    image: Madras,
  },
];

const TopColleges = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  return (
    <Box
      sx={{
        background: "white",
        paddingTop: "30px",
        paddingBottom: "30px",
        color: "#004D40",
        width: "100%",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "2rem", md: "2.5rem" },
            color: "#004D40",
            letterSpacing: "-0.05em",
          }}
        >
          Best Engineering Colleges in India
        </Typography>
      </Box>

      {/* Cards */}
      <Grid container spacing={3} justifyContent="center">
        {allColleges.map((college, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                height: "320px", // Increased card height
                width: "320px",
                margin: "10px",
                display: "flex",
                flexDirection: "column", // Ensures button stays at the bottom
              }}
              onClick={() => handleCardClick(index)}
            >
              <CardMedia
                component="img"
                height="140px"
                image={college.image}
                alt={college.name}
                sx={{
                  objectFit: "cover",
                  filter: "brightness(90%)",
                  width: "100%",
                }}
              />
              <CardContent
                sx={{
                  padding: "15px",
                  textAlign: "center",
                  flexGrow: 1, // Allow content to take up available space
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    color: "#004D40",
                  }}
                >
                  {college.name}
                </Typography>

                {/* Description Box */}
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "300",
                      fontSize: "0.85rem",
                      color: "#00695C",
                    }}
                  >
                    {college.description}
                  </Typography>
                </Box>
              </CardContent>

              {/* "Read More" Button */}
              <Box sx={{ padding: "10px", textAlign: "center" }}>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontWeight: "bold",
                    borderRadius: "20px",
                    borderColor: "#004D40",
                    color: "#004D40",
                  }}
                >
                  Read More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* "Explore More" Button */}
      <Box sx={{ textAlign: "center", marginTop: "30px" }}>
        <Button
          component="a"
          href="https://www.getmycollege.com/colleges?state=karnataka"
          rel="noopener noreferrer"
          variant="contained"
          sx={{
            backgroundColor: "#004D40",
            color: "#E0F2F1",
            fontWeight: "bold",
            padding: "12px 24px",
            fontSize: "1.1rem",
            borderRadius: "25px",
            transition: "background-color 0.3s ease",
            "&:hover": {
              backgroundColor: "#00695C",
            },
          }}
        >
          Explore More
        </Button>
      </Box>
    </Box>
  );
};

export default TopColleges;
