import React from "react";
import { Box, Typography } from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const ApplicationProcess = () => {
  const steps = [
    {
      id: 1,
      title: "Complete Application Form",
      description: "Fill out the online application form with your basic details.",
      icon: <AssignmentIcon sx={{ fontSize: { xs: "2rem", md: "2.5rem" }, color: "#ff6f40" }} />, // Matching orange icon color
    },
    {
      id: 2,
      title: "Self-Report Academic Records",
      description: "Submit details of your academic qualifications and transcripts.",
      icon: <SchoolIcon sx={{ fontSize: { xs: "2rem", md: "2.5rem" }, color: "#ff6f40" }} />, // Matching orange icon color
    },
    {
      id: 3,
      title: "Choose Your Program",
      description: "Select the engineering program of your choice.",
      icon: <MenuBookIcon sx={{ fontSize: { xs: "2rem", md: "2.5rem" }, color: "#ff6f40" }} />, // Matching orange icon color
    },
    {
      id: 4,
      title: "Submit Supporting Documents",
      description: "Upload required documents such as identity proof and photographs.",
      icon: <AttachFileIcon sx={{ fontSize: { xs: "2rem", md: "2.5rem" }, color: "#ff6f40" }} />, // Matching orange icon color
    },
  ];

  return (
    <Box sx={{ textAlign: "center", padding: "40px", background: "White" }}>
      <Typography
        variant="h4"
        sx={{
          marginBottom: "20px",
          fontSize: { xs: "1.8rem", sm: "2rem", md: "2.5rem" }, 
          fontWeight: "bold",
          color: "Black",
        }}
      >
        How to Apply to Best Engineering College In India
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, 
          justifyContent: "center", 
          gap: "20px", 
          flexWrap: "wrap",
          alignItems: "center", 
        }}
      >
        {steps.map((step) => (
          <Box
            key={step.id}
            sx={{
              width: { xs: "90%", sm: "80%", md: "250px" }, 
              height: { xs: "150px", sm: "150px", md: "150px" }, 
              justifyContent: "center",
              backgroundColor: "#ffffff", 
              padding: "10px", 
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
              marginBottom: "20px",
              transition: "all 0.3s ease", 
              "&:hover": {
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", 
              },
            }}
          >
            {/* Icon */}
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "1.6rem", md: "1.8rem" }, 
                marginBottom: "10px",
                color: "#004080", 
              }}
            >
              {step.icon}
            </Typography>
            {/* Step Title */}
            <Typography
              variant="h6"
              sx={{
                margin: "10px 0",
                fontSize: { xs: "1rem", md: "1.2rem" },
                fontWeight: "600",
                color: "#004080", 
              }}
            >
              {step.title}
            </Typography>
            {/* Step Description */}
            <Typography
              variant="body2"
              sx={{
                color: "#616161", 
                fontSize: { xs: "0.8rem", md: "0.9rem" }, 
                lineHeight: "1.5",
              }}
            >
              {step.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ApplicationProcess;
