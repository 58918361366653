import React from "react";
import { Box, Typography, Grid, CardMedia, Divider } from "@mui/material";
import Saveme from "../assests/choose.jpg";
import { FaRegLightbulb, FaHandsHelping, FaChartLine } from "react-icons/fa";

const AboutPage = () => {
  return (
    <Box sx={{ backgroundColor: "#3c6684", padding: { xs: "40px 20px", sm: "80px 60px" } }}>
      {/* Title Section */}
      <Box sx={{ textAlign: "center", marginBottom: "40px" }}>
        <Typography variant="h5" color="white">
          We are dedicated to bringing innovation, creativity, and top-tier service to businesses worldwide.
        </Typography>
      </Box>

      {/* Container with Grid */}
      <Grid container spacing={4} sx={{ alignItems: "center" }}>
        {/* Left Column (Image Container) */}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: "#B2EBF2",
              padding: "15px",
              borderRadius: "15px",
              boxShadow: 3,
            }}
          >
            <CardMedia
              component="img"
              height={{ xs: "200px", sm: "250px" }}
              image={Saveme}
              alt="About Image"
              sx={{
                objectFit: "cover",
                borderRadius: "15px",
                mx: "auto",
              }}
            />
          </Box>
        </Grid>

        {/* Right Column (Information) */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "20px",color:"white" }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Why Choose Us?
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ marginBottom: "20px", fontSize: { xs: "1rem", sm: "1rem" },color:"white"  }}
            >
              We are a team of passionate professionals dedicated to providing exceptional solutions to help businesses thrive in the digital era. 
              With years of experience in various industries, we ensure that our clients receive top-tier service and strategic direction for their growth.
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontSize: { xs: "1rem", sm: "1rem" },color:"white"  }}
            >
              Our mission is to help clients achieve their strategic goals through innovation, creativity, and cutting-edge technology.
              We focus on delivering sustainable results and long-term success by working closely with each of our clients to understand their unique challenges.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Divider Section */}
      <Divider sx={{ margin: "40px 0", borderColor: "black" }} />

      
      <Grid
        container
        spacing={{ xs: 4, md: 0 }} 
        sx={{ textAlign: "center" }}
      >
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              padding: "15px",
              boxShadow: 2,
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              maxWidth: "320px",
              margin: "0 auto",
              marginBottom: { xs: "16px", md: "0" }, 
            }}
          >
            <FaRegLightbulb size={30} color="#3f51b5" />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ marginTop: "10px", marginBottom: "8px", fontSize: "1rem" }}
            >
              Innovation
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "0.85rem" }}
            >
              We prioritize creativity and forward-thinking solutions to solve problems and enhance business outcomes.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              padding: "15px",
              boxShadow: 2,
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              maxWidth: "320px",
              margin: "0 auto",
              marginBottom: { xs: "16px", md: "0" },
            }}
          >
            <FaHandsHelping size={30} color="#3f51b5" />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ marginTop: "10px", marginBottom: "8px", fontSize: "1rem" }}
            >
              Collaboration
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "0.85rem" }}
            >
              We work hand-in-hand with our clients, providing continuous support and guidance to achieve shared goals.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              padding: "15px",
              boxShadow: 2,
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              maxWidth: "320px",
              margin: "0 auto",
              marginBottom: { xs: "16px", md: "0" },
            }}
          >
            <FaChartLine size={30} color="#3f51b5" />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ marginTop: "10px", marginBottom: "8px", fontSize: "1rem" }}
            >
              Growth
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "0.85rem" }}
            >
              Our solutions are designed to scale and evolve, ensuring long-term success and measurable results.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutPage;
