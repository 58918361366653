import React, { useState } from "react";
import { Grid, Typography, Button, Box, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../assests/bestengineeringcollegesinindia.com.png";

const EducationHeader = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const menuItems = [
        { label: "Home", href: "#" },
        { label: "About", href: "https://www.getmycollege.com/about-us" },
        { label: "Contact", href: "https://www.getmycollege.com/contact-us" },
    ];

    return (
        <Box>
            {/* Main Header Box */}
            <Box
                sx={{
                    background: "linear-gradient(45deg, #3c6684, #a1d3f2)", // Updated background colors
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                    padding: { xs: "10px", md: "10px 20px" },
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ flexWrap: "nowrap" }}
                >
                    {/* Logo Section */}
                    <Grid item xs="auto">
                        <Box
                            sx={{
                                width: 60,
                                height: 60,
                                borderRadius: "50%",
                                overflow: "hidden",
                                display: "inline-block",
                            }}
                        >
                            <img
                                src={logo}
                                alt="Logo"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Navigation Links for md and larger screens */}
                    <Grid
                        item
                        xs={12}
                        md="auto"
                        sx={{
                            display: { xs: "none", md: "flex" },
                            justifyContent: "center",
                            flexGrow: 1,
                        }}
                    >
                        {menuItems.map((item) => (
                            <Typography
                                variant="body1"
                                component="a"
                                href={item.href}
                                key={item.label}
                                sx={{
                                    textDecoration: "none",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    px: 3,
                                    "&:hover": { color: "#333" },
                                }}
                            >
                                {item.label}
                            </Typography>
                        ))}
                    </Grid>

                    {/* Join Us Button */}
                    <Grid item xs="auto">
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                component="a"
                                href="https://www.getmycollege.com/colleges?state=karnataka"
                                rel="noopener noreferrer"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#fff",
                                    color: "#E74C3C",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: { xs: "0.8rem", md: "1rem" },
                                    padding: { xs: "5px 10px", md: "8px 16px" },
                                    "&:hover": { backgroundColor: "#C0392B" },
                                }}
                            >
                                Best Engineering College in India
                            </Button>
                        </Box>
                    </Grid>

                    {/* Hamburger Menu (only for small screens) */}
                    <Grid
                        item
                        xs="auto"
                        sx={{
                            display: { xs: "block", md: "none" },
                            textAlign: "right",
                        }}
                    >
                        <IconButton onClick={toggleDrawer(true)} sx={{ color: "#fff" }}>
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>

            {/* Drawer for xs screen */}
            <Drawer
                anchor="left" // Always open from the left
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        width: 240,
                        display: "flex",
                        flexDirection: "column",
                        padding: 2,
                        backgroundColor: "White", // Updated background colors
                        color: "Black",
                        height: "100%",
                    }}
                >
                    {/* Close Button */}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton onClick={toggleDrawer(false)} sx={{ color: "black" }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Logo inside Drawer */}
                    <Box
                        sx={{
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                            width: "80px",
                            height: "80px",
                            borderRadius: "70%",
                            overflow: "hidden",
                            display: "inline-block",
                        }}
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>

                    {/* Line below Logo */}
                    <Box sx={{ borderBottom: "2px solid black", marginTop: "60px", marginBottom: "20px" }} />

                    {/* Menu items */}
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", paddingLeft: "20px" }}>
                        {menuItems.map((item) => (
                            <Typography
                                variant="h6"
                                key={item.label}
                                sx={{
                                    padding: "10px 0",
                                    textAlign: "left",
                                    cursor: "pointer",
                                    "&:hover": { color: "#ffa500" },
                                }}
                                onClick={() => {
                                    if (item.href) window.location.href = item.href;
                                    setDrawerOpen(false);
                                }}
                            >
                                {item.label}
                            </Typography>
                        ))}
                    </Box>
                </Box>
            </Drawer>

        </Box>
    );
};

export default EducationHeader;
