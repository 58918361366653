import React, { useEffect, useState } from "react";
import Cards from "../src/components/Cards"
import Header from "../src/components/Header"
import Banner from "../src/components/Banner"
import Footer from "../src/components/Footer"
import Whytochoose from "../src/components/Whytochoose"
import AutoPopup from "./components/AutopopUp";
import Apply from "./components/Apply";
import { Grid } from "@mui/material";

function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 25000);
  })
    
    return (
      <Grid sx={{overflowX: 'hidden'}}>
        <Header/>
        <Banner/>
        <Cards/>
        <Whytochoose/>
        <Apply/>
        <Footer/>
        <AutoPopup
          isOpen={isAutoPopupOpen}
          onClose={handleCloseAutoPopup}
          leadFlag="Organic"
        />
       
       </Grid>
    );
  }
  export default App;