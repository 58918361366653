import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  
  

  const footerBottomStyle = {
    backgroundColor: '#3c6684', // Bottom section color
    color: 'white',
    textAlign: 'center',
    padding: '15px 0',
    marginTop: '20px',
  };

  return (
    <Box component="footer">
      {/* Footer Bottom Section */}
      <Box style={footerBottomStyle}>
        <Typography sx={{ fontSize: '16px' }}>
          © 2024 Top engineering Colleges in India. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;