import React from "react";
import banner1 from "../assests/banner1.png";
import { Box, Grid, Typography, TextField, Button, Card, CardContent } from "@mui/material";

function BannerWithForm() {
  return (
    <Box
      sx={{
        height: { md: "70vh", xs: "120vh" },
        backgroundImage: `url(${banner1})`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        maxWidth: { md: "1800px", xs: "1200px" },
        marginLeft: { md: "0px", xs: "-150px" },
        color: "#fff",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: "1200px",
          margin: "0 auto",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* Left Section - Text Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: { xs: "center", md: "left" },
            mt: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.5rem", md: "2rem" },
              lineHeight: "1.3",
              marginLeft: { md: "-30px", xs: "130px" },
              color: { md: "white", xs: "white" },
              marginTop: { md: "80px" },
            }}
          >
            Start Your Journey Today!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              lineHeight: "1.8",
              marginLeft: { md: "-30px", xs: "130px" },
            }}
          >
            Discover courses tailored to your growth and make the most out of your learning experience. Join us now!
          </Typography>
        </Grid>

        {/* Right Section - Form Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: { md: "0px", xs: "120px" },
            marginBottom: { md: "70px", xs: "250px" },
          }}
        >
          <Card
            sx={{
              width: "100%",
              maxWidth: "400px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
              backgroundColor: "rgba(255, 255, 255, 0.95)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#1976d2", // Updated color
                  textAlign: "center",
                  fontSize: "1.2rem",
                  fontWeight: "bold", // Added for bold text
                  mb: 2,
                }}
              >
                Get In Touch
              </Typography>
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                {/* Input Fields */}
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  label="Course"
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                />
                <TextField
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  required
                  size="small"
                />
                {/* Submit Button */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    mt: 2,
                    py: 1,
                    fontSize: "0.9rem",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BannerWithForm;
